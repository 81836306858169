






import ConfirmSignUpForm from '../../components/auth/ConfirmSignUpForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ConfirmSignUpPage',

  components: {
    ConfirmSignUpForm,
  },
});
