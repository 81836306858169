

























import Vue from 'vue';
import { AuthService } from '../../services/auth/AuthService';

export default Vue.extend({
  name: 'ConfirmSignUpForm',

  data() {
    return {
      isVerified: false,
    };
  },

  watch: {
    '$route.query.token': {
      immediate: true,
      async handler(token: string): Promise<void> {
        if (token) {
          try {
            await AuthService.confirmSignUp(token);
            this.isVerified = true;
          } catch (error) {
            this.isVerified = false;
          }
        }
      },
    },
  },
});
